import produce from "immer";
import {call, put, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {actions as clientActions} from "@sagas/client";
import {ToastSuccesComponent, ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import handleErrors from "@common/handleErrors";
import {calculateTotal} from "@src/utils/calculateTotal";
import generateTariffs from "@src/services/rate_service/createTariffs";
import i18n from "../../../../i18n";
const logger = new Logger("Client create");

const PREFIX = "@app/client/create";
export const ADD_CLIENT = `${PREFIX}ADD_CLIENT`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const ADD_CLIENT_SUCCESS = `${PREFIX}ADD_CLIENT_SUCCESS`;
export const UPLOAD_DOCUMENT = `${PREFIX}UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_SUCCESS = `${PREFIX}UPLOAD_DOCUMENT_SUCCESS`;
export const GET_CLIENT_STATS = `${PREFIX}GET_CLIENT_STATS`;
export const GET_CLIENT_STATS_SUCCESS = `${PREFIX}GET_CLIENT_STATS_SUCCESS`;
export const GET_CLIENT_DOCS = `${PREFIX}GET_CLIENT_DOCS`;
export const GET_CLIENT_DOCS_SUCCESS = `${PREFIX}GET_CLIENT_DOCS_SUCCESS`;
export const GET_CLIENT_DOCS_BYID = `${PREFIX}GET_CLIENT_DOCS_BYID`;
export const GET_CLIENT_DOCS_BYID_SUCCESS = `${PREFIX}GET_CLIENT_DOCS_BYID_SUCCESS`;
export const ADD_CONTACT_INFO = `${PREFIX}ADD_CONTACT_INFO`;
export const ADD_CONTACT_INFO_SUCCESS = `${PREFIX}ADD_CONTACT_INFO_SUCCESS`;
export const SAVE_TABLE = `${PREFIX}SAVE_TABLE`;
export const SAVE_TABLE_SUCCESS = `${PREFIX}SAVE_TABLE_SUCCESS`;
export const GET_CONTACT_DATA = `${PREFIX}GET_CONTACT_DATA`;
export const GET_CONTACT_DATA_SUCCESS = `${PREFIX}GET_CONTACT_DATA_SUCCESS`;
export const CLEAR_CLIENT_DOCS = `${PREFIX}CLEAR_CLIENT_DOCS`;
export const DELETE_UPLOADED_DOCUMENT = `${PREFIX}DELETE_UPLOADED_DOCUMENT`;
export const DELETE_UPLOADED_DOCUMENT_STATE = `${PREFIX}DELETE_UPLOADED_DOCUMENT_STATE`;
export const UPLOAD_DOCUMENT_SUCCESS_EDIT = `${PREFIX}UPLOAD_DOCUMENT_SUCCESS_EDIT`;
export const SET_LOADING_DOCUMENTS = `${PREFIX}SET_LOADING_DOCUMENTS`;

const _state = {
	loading: false,
	stats: [],
	contractDocuments: [],
	additionalDocuments: [],
	doc: {},
	contact: null,
	total: null,
	documents: [],
	clientAdded: false,
	loadingDocuments: false,
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case ADD_CLIENT_SUCCESS:
				draft.clientAdded = action.payload;
				break;
			case UPLOAD_DOCUMENT_SUCCESS_EDIT:
				if (action.payload.typeStatisticsDocument === "CONTRACT_DOCUMENTS") {
					const updatedContractDocuments = state.contractDocuments.map((item) => {
						if (item.id === action.payload.id) {
							return {
								...item,
								fileName: action.payload.fileName,
								from: action.payload.from,
								to: action.payload.to,
								clientId: action.payload.clientId,
								uploadDocumentUrl: action.payload.uploadDocumentUrl,
								typeStatisticsDocument: action.payload.typeStatisticsDocument,
							};
						}
						return item;
					});
					draft.contractDocuments = updatedContractDocuments;
				} else {
					const updatedAdditionalDocuments = state.additionalDocuments.map((item) => {
						if (item.id === action.payload.id) {
							return {
								...item,
								fileName: action.payload.fileName,
								from: action.payload.from,
								to: action.payload.to,
								clientId: action.payload.clientId,
								uploadDocumentUrl: action.payload.uploadDocumentUrl,
								typeStatisticsDocument: action.payload.typeStatisticsDocument,
							};
						}
						return item;
					});
					draft.additionalDocuments = updatedAdditionalDocuments;
				}
				break;

			case GET_CLIENT_STATS_SUCCESS:
				const arr = action.payload.map((item) => ({
					...item,
					total: item?.receiveCommisionStatistics + item?.additionalRewards,
				}));
				draft.total = calculateTotal(arr);
				draft.stats = arr;
				break;
			case GET_CLIENT_DOCS_SUCCESS:
				draft.additionalDocuments = action.payload.additionalDocuments;
				draft.contractDocuments = action.payload.contractDocuments;
				break;
			case GET_CLIENT_DOCS_BYID_SUCCESS:
				draft.doc = action.payload;
				break;
			case ADD_CONTACT_INFO_SUCCESS:
				draft.contact = action.payload;
				break;
			case GET_CONTACT_DATA_SUCCESS:
				draft.contact = action?.payload;
				break;
			case CLEAR_CLIENT_DOCS:
				draft.doc = {};
				break;
			case DELETE_UPLOADED_DOCUMENT_STATE:
				if (action?.payload.type === "CONTRACT_DOCUMENTS") {
					draft.contractDocuments = state.contractDocuments.filter(
						(item) => item?.id !== action?.payload?.id,
					);
				} else {
					draft.additionalDocuments = state.additionalDocuments.filter(
						(item) => item?.id !== action?.payload?.id,
					);
				}
				break;
			case UPLOAD_DOCUMENT_SUCCESS:
				if (action?.payload.type === "CONTRACT_DOCUMENTS") {
					draft.contractDocuments = [action.payload.item, ...state.contractDocuments];
				} else {
					draft.additionalDocuments = [action.payload.item, ...state.additionalDocuments];
				}
				break;
			case SET_LOADING_DOCUMENTS:
				draft.loadingDocuments = action?.payload;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	addClient: (payload) => createAction(ADD_CLIENT, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	addClientSucces: (payload) => createAction(ADD_CLIENT_SUCCESS, {payload}),
	uploadDocument: (payload) => createAction(UPLOAD_DOCUMENT, {payload}),
	uploadDocumentSucess: (payload) => createAction(UPLOAD_DOCUMENT_SUCCESS, {payload}),
	uploadDocumentSucessEdit: (payload) => createAction(UPLOAD_DOCUMENT_SUCCESS_EDIT, {payload}),
	getClientStats: (payload) => createAction(GET_CLIENT_STATS, {payload}),
	getClientStatsSuccess: (payload) => createAction(GET_CLIENT_STATS_SUCCESS, {payload}),
	getClientDocs: (payload) => createAction(GET_CLIENT_DOCS, {payload}),
	getClientDocsSuccess: (payload) => createAction(GET_CLIENT_DOCS_SUCCESS, {payload}),
	getClientDocsById: (payload) => createAction(GET_CLIENT_DOCS_BYID, {payload}),
	getClientDocsByIdSuccess: (payload) => createAction(GET_CLIENT_DOCS_BYID_SUCCESS, {payload}),
	addContactInfo: (payload) => createAction(ADD_CONTACT_INFO, {payload}),
	addContactInfoSuccess: (payload) => createAction(ADD_CONTACT_INFO_SUCCESS, {payload}),
	saveTable: (payload) => createAction(SAVE_TABLE, {payload}),
	saveTableSuccess: (payload) => createAction(SAVE_TABLE_SUCCESS, {payload}),
	getContactData: (payload) => createAction(GET_CONTACT_DATA, {payload}),
	getContactDataSuccess: (payload) => createAction(GET_CONTACT_DATA_SUCCESS, {payload}),
	clearClientDocs: (payload) => createAction(CLEAR_CLIENT_DOCS, {payload}),
	deleteUploadedDocument: (payload) => createAction(DELETE_UPLOADED_DOCUMENT, {payload}),
	deleteUploadedDocumentOnState: (payload) =>
		createAction(DELETE_UPLOADED_DOCUMENT_STATE, {payload}),
	setLoadingDocuments: (payload) => createAction(SET_LOADING_DOCUMENTS, {payload}),
};
export const sagas = {
	*addClient({payload}) {
		const {values, formActions, setModal, setFullLoader, rateServiceTariffs} = payload;

		try {
			yield put(actions.setLoading(true));
			const {createExternalTariffs, externalId, id, clientId} = values;
			let response;
			if (id) {
				const obj = {
					id,
					name: values?.clientName.trim(),
					clientId,
					firstName: values.firstName.trim(),
					lastName: values.lastName.trim(),
					email: values.email,
					createClientAccount: values.createClientAccount,
					clientPayoutModel: values?.clientPayoutModel,
					externalId,
				};
				response = yield axios.put(`/client/update`, obj);
				yield put(
					clientActions.editComissionOnState({
						...values,
						clientId: values?.clientId,
					}),
				);
				ToastSuccesComponent(i18n.t("CilentUpdateSuccess"));
			} else {
				const postObj = {
					clientName: values.clientName.trim(),
					clientId,
					firstName: values.firstName.trim(),
					lastName: values.lastName.trim(),
					email: values.email,
					createClientAccount: values.createClientAccount,
					clientPayoutModel: values?.clientPayoutModel,
					externalId,
				};
				response = yield axios.post(`/client/create/`, postObj);
				yield put(
					clientActions.addClientSuccess({
						...values,
						id: response.data.data,
						clientId: values?.clientId,
					}),
				);
				yield put(clientActions.clientAddIncreaseTotalSize());

				ToastSuccesComponent(i18n.t("ClientCreateSuccess"));
			}
			if (createExternalTariffs && externalId && !id) {
				setFullLoader(true);
				const id = response?.data?.data;
				const tariffs = rateServiceTariffs[externalId] || [];
				yield call(generateTariffs, {tariffs, clientId, id, setModal});
			} else {
				setModal(false);
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
			logger.error(error);
			const errors = error?.response?.data.message;
			if (Array.isArray(errors)) {
				handleErrors(errors, formActions);
			}
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*uploadDocument({payload}) {
		yield put(actions.setLoadingDocuments(true));
		try {
			if (payload.typeStatisticsDocument) {
				const response = yield axios.post(
					`/statistics_and_document/upload_document`,
					payload,
				);
				yield put(
					actions.uploadDocumentSucess({
						item: response.data.data,
						type: payload?.typeStatisticsDocument,
					}),
				);
				ToastSuccesComponent(i18n.t("DocumentUploaded"));
			} else {
				const response = yield axios.post(
					`/statistics_and_document/get_upload_document`,
					payload,
				);
				yield put(actions.uploadDocumentSucessEdit(response.data.data));
				ToastSuccesComponent(i18n.t("DocumentUploaded"));
			}
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoadingDocuments(false));
		}
	},
	*addContactInfo({payload}) {
		// yield put(actions.setLoading(true));
		const obj = {
			contactForDocument: payload?.firstName,
			emailForDocument: payload?.email,
			telephoneForDocument: payload?.phone,
			clientId: payload.clientId,
		};
		try {
			yield axios.post(`/statistics_and_document/update_contact_info`, obj);
			yield put(actions.addContactInfoSuccess(obj));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			// yield put(actions.setLoading(false));
		}
	},
	*getClientStats({payload}) {
		yield put(actions.setLoading(true));

		try {
			const response = yield axios.post(`/statistics_and_document/sales`, payload);
			yield put(actions.getClientStatsSuccess(response.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getClientDocs({payload}) {
		yield put(actions.setLoadingDocuments(true));
		try {
			const response = yield axios.get(`/statistics_and_document/all_documents/${payload}`);
			yield put(actions.getClientDocsSuccess(response.data.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoadingDocuments(false));
		}
	},
	*getClientDocsById({payload}) {
		yield put(actions.setLoadingDocuments(true));
		try {
			const response = yield axios.post(`/statistics_and_document/get_upload_document`, {
				id: payload,
			});
			yield put(actions.getClientDocsByIdSuccess(response?.data?.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			yield put(actions.setLoadingDocuments(false));
		}
	},
	*saveTable({payload}) {
		// yield put(actions.setLoading(true));
		try {
			const response = yield axios.post(`/statistics_and_document/create`, payload);
			yield put(actions.saveTableSuccess(response?.data?.data));
			ToastSuccesComponent(i18n.t("TableSavedSuccessfully"));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			// yield put(actions.setLoading(false));
		}
	},
	*getContactData({payload}) {
		// yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(
				`/statistics_and_document/contact_info?clientId=${payload}`,
			);
			yield put(actions.getContactDataSuccess(response.data.data));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			// yield put(actions.setLoading(false));
		}
	},
	*deleteUploadedDocument({payload}) {
		// yield put(actions.setLoading(true));
		try {
			yield axios.put(`/statistics_and_document/delete/${payload?.id}`);
			yield put(actions.deleteUploadedDocumentOnState(payload));
		} catch (error) {
			ToastErrorComponent(error?.response?.data?.message);
		} finally {
			// yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(ADD_CLIENT, sagas.addClient);
	yield takeLatest(UPLOAD_DOCUMENT, sagas.uploadDocument);
	yield takeLatest(GET_CLIENT_STATS, sagas.getClientStats);
	yield takeLatest(GET_CLIENT_DOCS, sagas.getClientDocs);
	yield takeLatest(GET_CLIENT_DOCS_BYID, sagas.getClientDocsById);
	yield takeLatest(ADD_CONTACT_INFO, sagas.addContactInfo);
	yield takeLatest(SAVE_TABLE, sagas.saveTable);
	yield takeLatest(GET_CONTACT_DATA, sagas.getContactData);
	yield takeLatest(DELETE_UPLOADED_DOCUMENT, sagas.deleteUploadedDocument);
};
