/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as orderActions} from "@sagas/orders";
import {useTranslation} from "react-i18next";
import {actions as calculationActions} from "@sagas/calculations";
import {BlobProvider, PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import "jspdf-autotable";
import {actions as navigation} from "@sagas/navigation";
import {DATE_FORMAT} from "@common/constants";
import moment from "moment";
import {checkRoles} from "@utils/checkRoles";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import {Popover, TextField} from "@material-ui/core";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import Button from "@common/components/button";
import ModalComponent from "@common/modal";
import {CloseModalIcon} from "@assets/SvgComponents";
import locale from "date-fns/locale/en-GB";
import DataGridTable from "@src/common/table/DataGridTable";
import {ordersLoadingOptions} from "@src/assets/lottie";
import HtmlTooltip from "@src/common/htmlTooltip";
import PreviewFile from "@src/common/messagesComponents/PreviewFileComponent";
import _ from "lodash";
import StatusBox from "@src/common/components/status-box";
import CustomPopover from "@src/common/Popover";
import BackButtonComponents from "@src/common/BackButton";
import Form from "@src/common/Form";
import {useUrlParams} from "@src/utils/useUrlParams";
import TableSkeleton from "@src/common/table/TableSkeleton";
import {Skeleton} from "antd";
import {exportCsvHandler, handleUploadFile, priceInputs} from "./calcDetailsHelper";
import {statusData} from "../CalulationColumns";
import PublishModal from "./PublishModal";
import {actionsFormatter} from "./actions";
import saveIcon from "../../../../assets/img/save-icon.svg";
import downloadLogo from "../../../../assets/img/downloadicon.svg";
import CalculationTablePdf from "./CalculationTablePdf";
import {
	calculationDetailsColumns,
	calculationDetailsColumnsExport,
	calculationDetailsColumnsSP,
} from "./CalculationDetailsColumns";

const CalculationDetails = (props) => {
	const {
		getCalculationById,
		singleCalculation,
		match,
		navigate,
		loggedUser,
		setOrderPaidDate,
		loadingCalc,
		billingDetails,
		deleteManualCorrection,
		loading,
		cleanSettlementState,
		uploadFile,
		setExcelData,
		parseCsv,
		uploadLoading,
		setManualCorrection,
		updateManualCorrection,
	} = props;
	const {id, userId} = match.params;
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id: modalId} = getUrlParams("modal", "id");
	const {t} = useTranslation();
	const [dates, setDates] = useState(null);
	const [paidDateModal, setPaidDateModal] = useState();
	const [publishCalculationModal, setPublishCalculationModal] = useState(false);
	const [publishInitialValues, setPublishInitialValues] = useState({
		type: "immediately",
		publishTime: null,
	});
	const handleCalcModal = (type, id) => setModal(type, id);
	const [previewFileModal, setPreviewFileModal] = useState({
		id: null,
		file: {},
	});
	const [popper, setPopperOpen] = useState(false);
	const [anchor, setAnchor] = useState(null);
	const [preview, setPreview] = useState(false);
	const [pdfBase64, setPdfBase64] = useState(null);
	const handleChange = (newValue) => {
		setDates(newValue);
	};
	const handleSetPrice = () => {
		handleCalcModal("price");
	};
	const handleSubmitDate = () => {
		setOrderPaidDate({
			date: moment(dates).valueOf(),
			uuid: match?.params?.id,
		});
		setPaidDateModal(false);
	};
	useEffect(() => {
		setDates(singleCalculation?.details?.paidAt);
	}, [singleCalculation]);
	const setPopper = (event) => {
		setPopperOpen(true);
		setAnchor(event.currentTarget);
	};
	const handleAnchor = () => {
		setAnchor(null);
		setPopperOpen(false);
	};
	useEffect(() => {
		if (modalId) {
			if (modal === "file") {
				const foundedFile = singleCalculation?.files?.find((file) => file?.id === modalId);
				const file = {
					fileType: foundedFile?.fileName?.split(".")?.pop().toLowerCase(),
					fileName: foundedFile?.fileName,
					fileUrl: foundedFile?.url,
				};
				setPreviewFileModal({
					id: modalId,
					file,
				});
			}
		}
	}, [singleCalculation, modal, modalId]);
	const actions = {
		key: "actions",
		name: t("Actions"),
		formatter: ({row}) => actionsFormatter(row, handleCalcModal),
		sortable: false,
		resizable: false,
		width: 50,
		cellClass: (row) => (row?.orderId !== null ? "price" : ""),
		headerCellClass: "headerStyles",
	};
	const filteredColumns =
		singleCalculation?.details?.clientPayoutModel !== "TARIFF_MODEL"
			? calculationDetailsColumns?.filter((col) => col.showOnV2 !== false)
			: calculationDetailsColumns;
	const columnsWithActions = [...filteredColumns, actions];

	useEffect(() => {
		getCalculationById({uuid: id, userId});
		return () => {
			cleanSettlementState();
		};
	}, [id]);

	const openPublishModalAndSetValues = (visible) => {
		setPublishCalculationModal(visible);
		if (singleCalculation?.details?.publishTime && visible) {
			setPublishInitialValues({
				type: "scheduleDate",
				publishTime: singleCalculation?.details?.publishTime,
			});
		}
	};
	const closeModalAndClearValues = () => {
		setPublishCalculationModal(false);
		setPublishInitialValues({
			type: "immediately",
			date: null,
		});
	};
	const items = [
		{
			key: "publish",
			label: (
				<PublishModal
					handleClose={closeModalAndClearValues}
					pdfBase64={pdfBase64}
					setPublishCalculationModal={setPublishCalculationModal}
					initialValues={publishInitialValues}
				/>
			),
		},
	];
	const formData = {
		inputs: priceInputs,
		title: modalId ? t("EditPrice") : t("AddPrice"),
		submitFn: (values) => {
			if (!modalId) {
				setManualCorrection({
					price: values?.price,
					description: values?.description,
					settledUuid: id,
					setModal,
					userId,
				});
			} else {
				updateManualCorrection({
					values: {
						price: values?.price,
						description: values?.description,
						fileId: values?.fileId,
					},
					uuid: id,
					manualCorrectionId: modalId,
					setModal,
					userId,
				});
			}
		},
		id: modalId,
		getUrl: "/manual-correction/get/:id",
	};
	return (
		<>
			<PreviewFile
				canDownload={false}
				showModal={modal === "file"}
				setPreviewPdf={handleCalcModal}
				previewFile={previewFileModal?.file}
			/>
			<Form
				formData={formData}
				open={modal === "price"}
				handleClose={() => handleCalcModal(false)}
				loading={loading}
			/>
			<DeleteModal
				open={modal === "delete"}
				setShowDeleteModal={handleCalcModal}
				confirm={() => {
					deleteManualCorrection({
						id: modalId,
						uuid: id,
					});
					handleCalcModal(false);
				}}
				modalType="permanantDelete"
				disabled={loading}
				handleClose={() => handleCalcModal(false)}
			/>
			<DeleteModal
				calculationModal={true}
				setShowDeleteModal={setPaidDateModal}
				confirm={() => handleSubmitDate()}
				open={paidDateModal}
				handleClose={() => setPaidDateModal(false)}
			/>
			<div className="dashboard__container">
				<div className="dashboard__left__text usersCalcDetails">
					{!_.isEmpty(singleCalculation) &&
						!_.isEmpty(billingDetails) &&
						pdfBase64 === null && (
							<BlobProvider
								document={
									<CalculationTablePdf
										data={singleCalculation}
										from="preview"
										billingDetails={billingDetails}
										id={id}
									/>
								}
							>
								{({blob}) => {
									const blobToBase64 = (blob) =>
										new Promise((resolve, reject) => {
											const reader = new FileReader();
											reader.onload = () => {
												const base64String = reader.result.split(",")[1];
												resolve(base64String);
											};
											reader.onerror = reject;
											reader.readAsDataURL(blob);
										});
									if (blob) {
										blobToBase64(blob)
											.then((base64String) => {
												setPdfBase64(
													`data:application/pdf;base64,${base64String}`,
												);
											})
											.catch((error) => {
												// eslint-disable-next-line no-console
												console.error(
													"Error converting Blob to Base64:",
													error,
												);
											});
									}
									return <div></div>;
								}}
							</BlobProvider>
						)}
					<div className="header-component">
						<div className="header-component-wrapper" style={{marginLeft: "25px"}}>
							<BackButtonComponents
								onClick={() => navigate("/admin/calculations")}
								className="back__button_client"
							/>
							<h1>{t("Settlement")} </h1>
						</div>

						{loadingCalc ? (
							<div
								style={{
									flex: 1,
								}}
							>
								<Skeleton.Input
									active
									style={{
										width: "calc(100% - 40px)",
										marginLeft: 20,
										borderRadius: 4,
									}}
								/>
							</div>
						) : (
							<>
								<div className="settlementData" style={{marginLeft: "25px"}}>
									<div className="settlementData-info">
										{singleCalculation?.details?.spRole === "AGENCY" ||
											(singleCalculation?.details?.spRole === "AGENCY-SP" ? (
												<span>
													{singleCalculation?.details?.agencyType ===
														"SIMPLE" ||
													singleCalculation?.details?.spRole ===
														"AGENCY_70"
														? singleCalculation.details.agencyName
														: singleCalculation.details?.spFullName}
												</span>
											) : (
												<span>{singleCalculation.details?.spFullName}</span>
											))}
										<span>
											{t("SettlementNumber")}: {id}
										</span>
										<span>
											{t("from")}:{" "}
											{moment(singleCalculation?.date).format(DATE_FORMAT)}
										</span>
										<div className="settlementData-info-status">
											<StatusBox
												statusData={statusData}
												row={
													singleCalculation?.details?.status !== null
														? t(singleCalculation?.details?.status)
														: singleCalculation?.details?.publishTime
														? t("Scheduled")
														: t("DRAFT")
												}
											/>
										</div>
									</div>
								</div>
								<div
									className="settlementData"
									style={{justifyContent: "flex-end", marginRight: "30px"}}
								>
									<div className="settlementData-exports">
										{checkRoles("setPaidDate") && (
											<div className="set__order__dates">
												{singleCalculation?.details?.status ===
												"PUBLISHED" ? (
													<>
														<Button
															text={t("Save")}
															handleChange={() =>
																setPaidDateModal(true)
															}
															icon={saveIcon}
															smallButtonWithTooltip
														/>
														<LocalizationProvider
															dateAdapter={AdapterDateFns}
															locale={locale}
														>
															<DatePicker
																value={dates}
																onChange={handleChange}
																maxDate={moment().valueOf()}
																inputFormat="dd/MM/yyyy"
																renderInput={(params) => (
																	<TextField
																		{...params}
																		onKeyDown={(e) =>
																			e?.preventDefault()
																		}
																	/>
																)}
															/>
														</LocalizationProvider>
													</>
												) : (
													<>
														{checkRoles("editManualCorr") && (
															<>
																<HtmlTooltip
																	title={
																		<span>{t("Upload")}</span>
																	}
																	placement="bottom"
																>
																	<div
																		style={{
																			pointerEvents:
																				uploadLoading
																					? "none"
																					: "",
																		}}
																	>
																		<label
																			htmlFor="settlementUpload"
																			className="createuserExport"
																		>
																			<img
																				src={downloadLogo}
																				alt=""
																				style={{
																					transform:
																						"rotate(180deg)",
																				}}
																			/>
																		</label>
																		<input
																			style={{
																				display: "none",
																			}}
																			type="file"
																			id="settlementUpload"
																			accept=".xls, .xlsx"
																			onChange={(event) => {
																				handleUploadFile(
																					event,
																					parseCsv,
																					uploadFile,
																					id,
																					setExcelData,
																					userId,
																				);
																			}}
																		/>
																	</div>
																</HtmlTooltip>
																<CustomPopover
																	menu={{items}}
																	customClass="publish__popover"
																	open={publishCalculationModal}
																	handleVisibility={
																		openPublishModalAndSetValues
																	}
																	trigger={["click"]}
																>
																	<div>
																		<Button
																			text={
																				singleCalculation
																					?.details
																					?.publishTime
																					? t(
																							"EditSchedule",
																					  )
																					: t("Publish")
																			}
																			minWidth="120px"
																			width="auto"
																			handleChange={
																				openPublishModalAndSetValues
																			}
																		/>
																	</div>
																</CustomPopover>
																<Button
																	text={t("SetPrice")}
																	minWidth="120px"
																	handleChange={() =>
																		handleSetPrice()
																	}
																/>
															</>
														)}
													</>
												)}
											</div>
										)}
										<div
											className="createuserExport"
											onClick={(e) => setPopper(e)}
										>
											<img alt="exportData" src={downloadLogo} />
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
				<Popover
					open={popper}
					anchorEl={anchor}
					// disableEnforceFocus
					onClose={() => handleAnchor()}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<div className="popover-exports">
						{singleCalculation?.data &&
						singleCalculation?.details?.status === "PUBLISHED" ? (
							<PDFDownloadLink
								document={
									<CalculationTablePdf
										data={singleCalculation}
										billingDetails={billingDetails}
										id={id}
										from="download"
									/>
								}
								fileName={`${
									singleCalculation?.details?.spRole === "AGENCY" ||
									singleCalculation?.details?.spRole === "AGENCY-SP"
										? singleCalculation?.details?.agencyType === "SIMPLE" ||
										  singleCalculation?.details?.spRole === "AGENCY_70"
											? singleCalculation.details.agencyName
											: singleCalculation.details?.spFullName
										: singleCalculation.details?.spFullName
								} ${moment(singleCalculation?.date).format(DATE_FORMAT)}.pdf`}
							>
								{({loading}) => (
									<>
										{loading ? (
											<span className="download__pdf-loading">
												{t("Loading")}
											</span>
										) : (
											<span>{t("Download")} PDF</span>
										)}
									</>
								)}
							</PDFDownloadLink>
						) : (
							<>
								{loadingCalc ? (
									<span className="download__pdf-loading">{t("Loading")}</span>
								) : (
									<span onClick={() => setPreview(true)}>{t("PreviewPDF")}</span>
								)}
							</>
						)}
						{checkRoles("exportTariff") && (
							<span
								onClick={() =>
									exportCsvHandler(
										loggedUser?.role?.name === "SP"
											? calculationDetailsColumnsSP
											: calculationDetailsColumnsExport,
										singleCalculation,
										match,
									)
								}
							>
								{t("Download")} {t("Billing")}
							</span>
						)}
					</div>
				</Popover>
				<div
					style={{
						padding: "20px",
					}}
				>
					<div className="loader__and__table-orderspage calculation_details-page">
						<DataGridTable
							columns={
								singleCalculation?.details?.status !== "PUBLISHED" &&
								checkRoles("editManualCorr")
									? columnsWithActions
									: calculationDetailsColumns
							}
							data={singleCalculation?.data || []}
							loading={loadingCalc}
							pagination={false}
							enableCellSelect={false}
						/>
						{loadingCalc && (
							<div className="infinitscroll_loader center">
								<TableSkeleton
									options={ordersLoadingOptions}
									height={60}
									width={60}
								/>
							</div>
						)}
					</div>
				</div>
				<ModalComponent
					open={preview}
					handleClose={() => setPreview(false)}
					disableEnforceFocus
					customClassNames="sales__tracker__modal-previewPdf"
					width={700}
					positionModal="right"
				>
					<div className="sales__tracker__modal-previewPdf-header">
						<span className="sales__tracker__modal-previewPdf-header--id">{id}</span>
						<div
							className="sales__tracker__modal-previewPdf-header--closeicon"
							onClick={() => setPreview(false)}
						>
							<CloseModalIcon fill="#000" width="10px" height="10px" />
						</div>
					</div>
					<PDFViewer width="100%" height="100%">
						<CalculationTablePdf
							data={singleCalculation}
							from="preview"
							billingDetails={billingDetails}
							id={id}
						/>
					</PDFViewer>
				</ModalComponent>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	page: state.app.calculations.index.page,
	loadingCalc: state.app.calculations.index.loading,
	totalSize: state.app.calculations.index.totalSize,
	size: state.app.calculations.index.size,
	singleCalculation: state.app.calculations.index.singleCalculation,
	loggedUser: state.app.users.index.loggedUser,
	billingDetails: state.app.userProfile.settings.billingDetails,
	loading: state.app.orders.index.loading,
	uploadLoading: state.app.calculations.index.uploadLoading,
});

const mapDispatchToProps = {
	setOrderPaidDate: orderActions.setOrderPaidDate,
	getCalculationById: calculationActions.getCalculationById,
	editPage: calculationActions.editPage,
	editSize: calculationActions.editSize,
	navigate: navigation.navigate,
	deleteManualCorrection: orderActions.deleteManualCorrection,
	cleanSettlementState: calculationActions.cleanSettlementState,
	uploadFile: calculationActions.uploadCalculationFile,
	setExcelData: calculationActions.setExcelData,
	parseCsv: calculationActions.parseCsv,
	setManualCorrection: orderActions.setManualCorrection,
	updateManualCorrection: orderActions.editManualCorrection,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CalculationDetails));
