import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {actions as clientActions} from "@sagas/client";
import {actions as createClientActions} from "@sagas/client/create";
import {actions as deleteClientActions} from "@sagas/client/delete";
import {actions as deletedClientActions} from "@sagas/client/deleted";
import {actions as restoreClientActions} from "@sagas/client/restore";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import {useTranslation} from "react-i18next";
import {checkRoles} from "@utils/checkRoles";
import Button from "@common/components/button";
import {ClientsIcon, DeleteRowIcon, EditRowIcon, RestoreRowIcon} from "@assets/SvgComponents";
import DataGridTable from "@src/common/table/DataGridTable";
import HtmlTooltip from "@src/common/htmlTooltip";
import Form from "@src/common/Form";
import {useUrlParams} from "@src/utils/useUrlParams";
import useRateServiceData from "@src/services/rate_service/clients";
import {clientColumnsRemote, clientColumnsRemoteDeleted} from "./ClientsColumns";
import {clientElements} from "./clientElements";
import userPlus from "../../../assets/img/user-plus.svg";

const Clients = (props) => {
	const {
		getClients,
		editPage,
		editSize,
		deleteClient,
		addClient,
		loading,
		fetchDeleted,
		deletedClientData,
		editPageDeleted,
		editSizeDeleted,
		restoreClient,
		clientState,
	} = props;
	const {clients, page, totalPages, size, totalSize, isLoading} = clientState;
	const {t} = useTranslation();
	const [reEditPage, setReEditPage] = useState(true);
	const [fullLoader, setFullLoader] = useState(false);
	const [rateServiceClients, rateServiceTariffs] = useRateServiceData();

	const resetPage = () => {
		if (tableTab === "active") {
			editPage(1);
		} else {
			editPageDeleted(1);
		}
	};

	const {getUrlParams, setUrlParams, setQuery, setModal} = useUrlParams(resetPage);
	const {tableTab, query, modal, id} = getUrlParams("table-tab", "query", "modal", "id");

	const {lists} = clientElements({
		hasId: id,
		rateServiceClients,
	});

	const initializeParams = () => {
		setUrlParams(tableTab || "active", "table-tab", {
			paramName: "query",
			paramValue: {search: ""},
		});
	};

	useEffect(() => {
		initializeParams();
		return () => editPage(1);
	}, []);

	useEffect(() => {
		if (!modal) {
			setFullLoader(false);
		}
	}, [modal]);

	const actionsFormatter = (row) => (
		<div className="table-actions-wrapper">
			{checkRoles("editClient") && (
				<HtmlTooltip title={<span>{t("editClient")}</span>}>
					<span onClick={() => handleClientModal("client", row?.id)}>
						<EditRowIcon />
					</span>
				</HtmlTooltip>
			)}
			{checkRoles("deactivateClient") && (
				<HtmlTooltip title={<span>{t("deleteClient")}</span>}>
					<span onClick={() => handleClientModal("deactivate", row?.id)}>
						<DeleteRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actions = {
		formatter: ({row}) => actionsFormatter(row),
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: 50,
		headerCellClass: "headerStyles",
	};
	const actionsDeletedFormatter = (row) => (
		<div className="table-actions-wrapper" style={{justifyContent: "center"}}>
			{checkRoles("activateClient") && (
				<HtmlTooltip title={<span>{t("ActivateClient")}</span>}>
					<span onClick={() => handleClientModal("restore", row?.id)}>
						<RestoreRowIcon />
					</span>
				</HtmlTooltip>
			)}
		</div>
	);
	const actionsDeleted = {
		formatter: ({row}) => actionsDeletedFormatter(row),
		key: "actions",
		name: t("Actions"),
		sortable: false,
		resizable: false,
		width: 50,
		headerCellClass: "headerStyles",
	};
	const handleSearch = (value) => {
		setQuery({search: value.target.value});
	};
	const clearFilters = () => {
		setQuery({search: ""});
	};
	useEffect(() => {
		if (tableTab === "active") {
			getClients({search: query?.search || ""});
		}
	}, [page, size, query?.search, tableTab]);
	useEffect(() => {
		if (tableTab === "inactive") {
			fetchDeleted({search: query?.search || ""});
		}
	}, [deletedClientData?.page, query?.search, deletedClientData?.size, tableTab]);
	const columnsWithActions = [...clientColumnsRemote, actions];
	const deletedColumnsWithActions = [...clientColumnsRemoteDeleted, actionsDeleted];

	if (!tableTab) return null;

	const tableProps = {
		active: {
			columns: columnsWithActions,
			data: clients,
			loading: isLoading,
			size,
			totalSize,
			editPage,
			editSize,
			page,
			totalPages,
		},
		inactive: {
			data: deletedClientData?.list,
			editPage: editPageDeleted,
			editSize: editSizeDeleted,
			columns: deletedColumnsWithActions,
			totalPages: deletedClientData.totalPages,
			size: deletedClientData.size,
			page: deletedClientData.page,
			totalSize: deletedClientData.totalSize,
			loading: deletedClientData.loading,
		},
	};
	const handleTab = (item) => {
		if (item !== tableTab) {
			clearFilters();
			setUrlParams(item, "table-tab");
		}
	};

	const formData = {
		inputs: lists.add,
		id,
		title: id ? t("EditClient") : t("AddClient"),
		submitFn: (values, actions) => {
			addClient({values, formActions: actions, setModal, setFullLoader, rateServiceTariffs});
		},
		getUrl: "/client/:id",
	};
	const handleClientModal = (show, id) => setModal(show, id);

	const modalConfig = {
		deactivate: {
			confirm: () => deleteClient({id, handleClientModal}),
			type: "deactivate",
		},
		restore: {
			confirm: () => restoreClient({id, handleClientModal}),
			modalType: "activate",
			type: "restore",
		},
	};
	const activeModal = modal ? modalConfig[modal] : null;
	return (
		<>
			{activeModal && (
				<DeleteModal
					setShowDeleteModal={() => handleClientModal(false)}
					confirm={activeModal.confirm}
					word={t("thisClient")}
					open={activeModal.type === modal}
					handleClose={() => handleClientModal(false)}
				/>
			)}
			<Form
				open={modal === "client"}
				handleClose={() => handleClientModal(false)}
				formData={formData}
				loading={loading}
				fullLoader={fullLoader}
				fullLoaderText={t("tariffsInProcess")}
			/>
			<div className="dashboard__container clients">
				<div className="header-component">
					<div className="dashboard__left__text-header">
						<div className="header-component-wrapper">
							<ClientsIcon />
							<span className="header-component-text">{t("ClientsList")}</span>
						</div>
					</div>
					<div className="dashboard__left__text-actions">
						{checkRoles("addClient") && (
							<Button
								text={t("CreateClient")}
								icon={userPlus}
								leftRounded={false}
								handleChange={() => handleClientModal("client")}
								smallButtonWithTooltip
							/>
						)}
						<BigSearchBar
							value={query?.search || ""}
							handleSearch={handleSearch}
							headerSearch
						/>
					</div>
				</div>

				<div className="clientTable">
					<div className="loader__and__table">
						<DataGridTable
							withTabs
							currentTab={tableTab}
							onTabClick={handleTab}
							pagination
							reEditPage={reEditPage}
							setReEditPage={setReEditPage}
							{...tableProps[tableTab]}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	clientState: state.app.client.index,
	loading: state.app.client.create.loading,
	deletedClientData: state.app.client.deleted,
});

const mapDispatchToProps = {
	getClients: clientActions.fetchClients,
	editPage: clientActions.editPage,
	editSize: clientActions.editSize,
	addClient: createClientActions.addClient,
	deleteClient: deleteClientActions.deleteClient,
	fetchDeleted: deletedClientActions.fetch,
	editPageDeleted: deletedClientActions.editPage,
	editSizeDeleted: deletedClientActions.editSize,
	restoreClient: restoreClientActions.restore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
