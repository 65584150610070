/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import axios from "axios";

const {REACT_APP_ONERI_REQUESTS} = process.env;

const REACT_APP_RATE_SERVICE_URL = "https://gateway.eg-on.com/";
const REACT_APP_RATE_SERVICE_AUTHORIZATION =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJ4NVhTUHVaeDhNM3NKRE5yZCIsImFwcElkIjoia0ZNelk5WkRxb2s1NUVIYXciLCJzZXJ2aWNlTmFtZSI6IlVzZXJTZXJ2aWNlIiwiZWdvbkFwaUtleSI6IjgyYjk2MjMyYjQyMTA4ZjdmZGJiMjY3YTg5NjQxOWIyIiwiaWF0IjoxNzEyMjE4MTY0fQ.yvtFVrBNgqKoY-1X5tvWsdrXTza7nOcV0kdxX--DmYo";

const rateServiceAxios = axios.create({
	baseURL: REACT_APP_ONERI_REQUESTS, // All requests should be sent to ONERI_REQUESTS
});

export const axiosRequests = {
	rateService: async ({url, data, json, method, baseURL}) =>
		rateServiceAxios.post(
			REACT_APP_ONERI_REQUESTS,
			(data = {
				url: `${baseURL || REACT_APP_RATE_SERVICE_URL}${url}`,
				method: method || "post",
				token: `Bearer ${REACT_APP_RATE_SERVICE_AUTHORIZATION}`,
				json,
				data,
			}),
		),
	axios: rateServiceAxios,
};
